@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-Regular.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-SemiBold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-Bold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  font-display: swap;
  src: url('/fonts/Montserrat-ExtraBold.woff2');
  font-weight: 800;
}

@layer base {
  html,
  body {
    @apply max-w-full scroll-pt-[20vh] scroll-smooth;
  }

  section {
    @apply pb-8;
  }

  p {
    @apply text-sm sm:text-base;
  }

  a,
  a:hover {
    @apply no-underline;
  }

  button {
    @apply border-none bg-transparent;
  }

  button:focus,
  input:focus,
  textarea:focus {
    @apply outline-none;
  }

  textarea {
    @apply resize-none;
  }
}

@layer components {
  .btn {
    @apply text-center inline-block rounded-full px-6 py-3 border-2 border-transparent;
  }

  .btn-metric-electrike {
    @apply bg-metric-electrike text-white hover:bg-metric-qwilfish transition-colors;
  }

  .btn-metric-hariyama {
    @apply bg-metric-hariyama text-white hover:bg-metric-qwilfish transition-colors;
  }

  .btn-metric-cinderace {
    @apply bg-metric-cinderace text-white hover:bg-metric-qwilfish transition-colors;
  }

  .btn-white {
    @apply bg-transparent text-white border-white hover:bg-white hover:text-black transition-colors;
  }
}

@layer utilities {
  .container-xl {
    @apply xl:max-w-[1575px] mx-auto px-4 lg:px-8;
  }

  .container-right {
    @apply container xl:!pl-32;
  }

  .margin-tight {
    @apply m-0;
  }

  .parsecontent-quote {
    @apply children-p:text-xl lg:children-p:text-4xl;
  }

  .parsecontent-3xl {
    @apply children-h2:text-4xl lg:children-h2:text-9xl;
  }

  .parsecontent-xl {
    @apply children-h1:text-4xl lg:children-h1:text-7xl children-h2:text-7xl children-p:text-xl;
  }

  .parsecontent-lg {
    @apply children-h2:text-4xl lg:children-h2:text-6xl lg:children-p:text-base children-h2:mb-6;
  }

  .parsecontent-md {
    @apply children-h1:text-3xl lg:children-h1:text-4xl 2xl:children-h1:text-5xl  children-h2:text-3xl lg:children-h2:text-5xl children-p:text-sm children-h2:mb-3;
  }

  .parsecontent-sm {
    @apply children-h2:text-lg lg:children-h2:text-2xl children-p:text-sm children-li:text-sm children-li:!leading-7 children-h2:mb-3 children-h3:text-lg;
  }

  .parsecontent-h {
    @apply children-h1:text-metric-hariyama children-h2:text-metric-hariyama children-h3:text-metric-cinderace children-h1:font-extrabold children-h2:font-extrabold;
  }

  .parsecontent-h-dark {
    @apply children-h2:text-metric-cinderace;
  }

  .ellipsis {
    @apply overflow-hidden text-ellipsis whitespace-nowrap;
  }

  .bg-glass {
    @apply backdrop-blur-[9.5px] shadow-sm bg-opacity-70;
  }

  .text-shadow {
    text-shadow: 0.05em 0.05em 0 hsl(150 0% 0%);
  }
}

/* ----------START PARSECONTENT---------- */
.parsecontent h1 {
  @apply mb-5;
}

.parsecontent strong,
.parsecontent b,
.parsecontent h1 strong,
.parsecontent h1 b,
.parsecontent h2 strong,
.parsecontent h2 b,
.parsecontent h3 strong,
.parsecontent h3 b,
.parsecontent h4 strong,
.parsecontent h4 b,
.parsecontent h5 strong,
.parsecontent h5 b {
  @apply font-bold;
}

.parsecontent-3xl h2 strong {
  @apply font-extrabold;
}

.parsecontent-extrabold h2 strong {
  @apply font-extrabold;
}

.parsecontent h1:last-child,
.parsecontent h2:last-child,
.parsecontent h3:last-child,
.parsecontent h4:last-child,
.parsecontent h5:last-child,
.parsecontent p:last-child {
  @apply mb-0;
}

.parsecontent a {
  @apply text-metric-electrike;
}

.parsecontent a:hover {
  @apply underline;
}

.parsecontent ul {
  @apply !pt-0 pl-8 sm:pl-16 sm:pt-8;
}

.parsecontent ul > li {
  @apply relative py-3 before:absolute before:bg-[url('/img/check.svg')] before:w-[24px] before:h-[17px] before:-left-[35px] before:top-[18px];
}

.check-light ul > li {
  @apply relative py-3 before:absolute before:!bg-[url('/img/check-light.svg')] before:w-[24px] before:h-[17px] before:-left-[35px] before:top-[10px];
}

.alignright {
  @apply float-right text-center;
}

.aligncenter {
  @apply my-0 mx-auto block;
}

.wp-caption {
  @apply max-w-full;
}

.wp-caption-text {
  @apply text-center text-base font-bold;
}
/* ----------END PARSECONTENT---------- */

/* ----------START FORM---------- */
.form-custom .form-duuf-input,
.form-custom .form-duuf-textarea {
  @apply h-12 w-full border-b-2 bg-transparent mb-3 text-metric-shiny-magneton px-4 !shadow-none !ring-0 placeholder:text-metric-shiny-magneton border-metric-shiny-magneton;
}

.form-duuf-group {
  @apply mb-5;
}

.form-duuf-input,
.form-duuf-textarea {
  @apply h-12 w-full border-b-2 bg-transparent mb-3 text-white px-4 !shadow-none !ring-0 placeholder:text-white;
}

.form-duuf-textarea {
  @apply pt-3 min-h-[155px];
}

.form-duuf-file-input {
  @apply text-slate-500 file:mr-4 file:rounded-full file:border-none file:bg-metric-electrike file:py-2 file:px-4 file:text-white file:hover:bg-metric-qwilfish file:hover:transition-all;
}

.form-duuf-checkbox {
  @apply mr-4 h-7 w-7 rounded-md border-none bg-slate-100 text-slate-500 focus:border-none focus:outline-none active:border-none active:outline-none;
}

.form-duuf-select__control {
  @apply h-12 !border-none !bg-slate-100 !shadow-none;
}

.form-duuf-select__menu {
  @apply rounded-lg;
}

.form-duuf-select__menu-list {
  @apply px-3 py-0;
}

.form-duuf-select__option {
  @apply my-1 cursor-pointer rounded-lg transition-all;
}

.form-duuf-select__option:first-of-type {
  @apply mt-3;
}

.form-duuf-select__option:last-of-type {
  @apply mb-3;
}

.form-duuf-select__option:active {
  @apply !bg-slate-500 !text-white;
}

.form-duuf-select__option--is-focused {
  @apply !bg-slate-100;
}

.form-duuf-select__option--is-selected {
  @apply !bg-slate-800;
}

.form-duuf-select__input {
  @apply !border-none !shadow-none !outline-none;
}

.form-duuf-select__single-value {
  @apply !top-[30px] !left-[12px] !m-0 !text-slate-800;
}

.form-duuf-select__placeholder {
  @apply !top-[24px] !left-[12px] !m-0 !text-slate-500;
}

.form-duuf-group {
  @apply relative;
}

.form-duuf-error {
  @apply absolute text-sm text-red-500 right-0 top-2;
}

.form-duuf-label {
  @apply px-2 text-white;
}

.form-duuf-submit-disabled {
  @apply opacity-50 hover:bg-blue-500;
}

.form-duuf-submit > button {
  @apply btn btn-metric-electrike w-full md:w-auto;
}

.form-duuf-loading {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white bg-opacity-50;
}
/* ----------START FORM---------- */
